const windowW = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
const windowH = () => Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

const yPosition = (index) => {
  if (windowW() < windowH()) {
    return 0;
  }

  return Math.min(index * 20, 40);
};

const xPosition = (index) => {
  if (windowW() > windowH()) {
    return 0;
  }

  return Math.min(index * 20, 40);
};

export const posedSlotVariants = {
  normal: ({ index }) => ({
    y: yPosition(index),
    x: xPosition(index),
    rotateY: 0,
    rotateZ: 0,
    scale: 1,
    opacity: 1,
    transformStyle: "preserve-3d",
    transition: { type: "spring", damping: 20, mass: 1 },
  }),
  selected: () => ({
    y: 0,
    x: 0,
    scale: 1,
    rotateY: 0,
    opacity: 1,
    transformStyle: "preserve-3d",
  }),
  turned: () => ({
    y: 0,
    x: 0,
    rotateY: 180,
    rotateZ: 0,
    scale: 1,
    opacity: 1,
    transformStyle: "preserve-3d",
    transition: { type: "spring", damping: 20, mass: 1 },
  }),
  played: ({ index }) => ({
    y: yPosition(index),
    x: xPosition(index),
    rotateY: 0,
    rotateZ: 0,
    scale: 1.0,
    opacity: 1,
    transformStyle: "preserve-3d",
    transition: { duration: 0.3, delay: 0.5 },
  }),
  discarded: {
    y: 0,
    x: 0,
    rotateY: 180,
    scale: 1,
    opacity: 1,
    transformStyle: "preserve-3d",
  },
  emphasized: {
    y: 0,
    x: 0,
    rotateY: 0,
    scale: 1.1,
    opacity: 1,
  },
  lowered: () => ({
    y: 0,
    x: 0,
    rotateY: 0,
    scale: 0.9,
    opacity: 0.5,
  }),
};

export const posedSelectionIconVariants = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.3 },
  },
  hidden: {
    opacity: 0,
    scale: 0,
  },
};