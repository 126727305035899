import React from "react";
import PropTypes from "prop-types";
import { range, uniqBy, sortBy, reverse } from "lodash";
import { motion, AnimatePresence } from "framer-motion";

import { CardSlot } from "..";
import { liftVerticalVariants, turnAnimationVariants } from "../card-collection-animation-poses";

import "./card-collection-view.scss";

const CardCollectionView = ({
  cards,
  slotCount = null,
  playedCardId,
  selection = [],
  actionsForCard = () => [],
  bottomTextForCard = () => null,
  isDisabled = false,
  hideCardsWhenDisabled = true,
  hideWhenNotPlayed = false,
  sortByVotes = false,
  groupByCardId = true,
  processingCardId = null,
  layoutDirection = "vertical",
  selectionAnimation = "turn-discarded", // turn-discarded | emphasize
  enterAnimation = "lift-vertical", // lift-vertical | turn
  onCardSelected = () => {},
  onCardDeselected = () => {},
}) => {
  let slots = slotCount !== null ? range(Math.min(cards.length, slotCount)) : range(cards.length);
  let cardCollection = cards;

  if (groupByCardId) {
    cardCollection = uniqBy(cards, (c) => c.id);
  }

  if (cardCollection.length !== cards.length) {
    slots = range(cardCollection.length);
  }

  if (sortByVotes) {
    cardCollection = reverse(sortBy(cardCollection, ["votes", "key"]));
  }

  const sizeClass = () => slots.length > 8 ? "small" : "normal";

  return (
    <div
      className={`card-collection-view size-${sizeClass()} layout-${layoutDirection} ${
        isDisabled ? "disabled" : ""
      }`}>
      <AnimatePresence>
        {slots.map((index) => {
          const card = cardCollection[index];
          const isSelected = selection.includes(card.id) || selection.includes(card.key);
          const isPlayed =
            !!playedCardId && (card.id === playedCardId || card.key === playedCardId);
          let cardsInSlot = [cards.find((c) => c.key === card.key)];

          if (groupByCardId) {
            cardsInSlot = sortBy(
              cards.filter((c) => c.id === card.id),
              "key",
            );
          }

          return (
            <motion.div
              variants={
                enterAnimation === "lift-vertical" ? liftVerticalVariants : turnAnimationVariants
              }
              initial="from"
              animate="enter"
              exit="exit"
              className="card-layout-posed-stack"
              key={index}
              custom={{ index }}>
              <CardSlot
                cards={cardsInSlot}
                slotIndex={index}
                isSelected={isSelected}
                isPlayed={isPlayed}
                hasPlayedCard={playedCardId !== null}
                animation={selectionAnimation}
                isDisabled={isDisabled}
                isProcessing={cardsInSlot.map((c) => c.id).includes(processingCardId)}
                hideWhenDisabled={hideCardsWhenDisabled}
                hideWhenNotPlayed={hideWhenNotPlayed}
                actions={actionsForCard(cardsInSlot[0])}
                bottomText={bottomTextForCard(cardsInSlot[0])}
                onCardSelected={(cardId, cardKey) => {
                  if (!isDisabled) {
                    onCardSelected(cardId, cardKey);
                  }
                }}
                onCardDeselected={(cardId, cardKey) => {
                  if (!isDisabled) {
                    onCardDeselected(cardId, cardKey);
                  }
                }}

              />
            </motion.div>
          );
        })}
      </AnimatePresence>
    </div>
  );
};

CardCollectionView.propTypes = {
  slotCount: PropTypes.number,
  sortByVotes: PropTypes.bool,
  selection: PropTypes.array,
  playedCardId: PropTypes.string,
  cards: PropTypes.array,
  groupByCardId: PropTypes.bool,
  selectionAnimation: PropTypes.string,
  enterAnimation: PropTypes.string,
  isDisabled: PropTypes.bool,
  layoutDirection: PropTypes.oneOf(["vertical", "horizontal"]),
  hideCardsWhenDisabled: PropTypes.bool,
  hideWhenNotPlayed: PropTypes.bool,
  onCardSelected: PropTypes.func.isRequired,
  onCardDeselected: PropTypes.func.isRequired,
  actionsForCard: PropTypes.func,
  bottomTextForCard: PropTypes.func,
  processingCardId: PropTypes.string,
};

export default CardCollectionView;
